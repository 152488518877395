<template>
  <div>
    <h5 class="font-weight-bold ">
      CAV Issuances
    </h5>
    <div class="card rounded shadow-sm mt-3">
      <div class="card-body">
        <div id="filters" class="row">
          <div class="col-6">
            <label for="">Search Database By Name</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <button
                  id="empty-fields-button"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="clear"
                >
                  <i class="bi bi-arrow-clockwise text-dark"></i>
                </button>
              </div>

              <input
                type="text"
                class="form-control"
                placeholder="SECPA No"
                v-model="params.secpa_no"
              />
              <input
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="params.lastname"
              />

              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="params.firstname"
              />

              <input
                type="text"
                class="form-control"
                placeholder="Middle Name"
                v-model="params.middlename"
              />

              <div class="input-group-append">
                <button
                  id="cav-search-btn"
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="search"
                  :disabled="
                    !(
                      params.secpa_no !== '' ||
                      params.lastname !== '' ||
                      params.firstname !== '' ||
                      params.middlename !== ''
                    )
                  "
                >
                  <i class="bi bi-search text-dark"></i>
                </button>
              </div>
            </div>
          </div>

          <!---------------------- FILTER DATA --------------------------->

          <div class="col-6">
            <label for="">Filter Data</label>
            <div class="input-group">
              <select id="select-heis" class="custom-select" disabled>
                <option value="" selected disabled hidden>Choose HEI</option>
              </select>

              <select id="" class="custom-select" disabled>
                <option value="" selected disabled hidden
                  >Choose a program
                </option>
              </select>

              <select id="" class="custom-select" disabled>
                <option value="" selected disabled>Choose a year level</option>
              </select>
              <div class="input-group-append">
                <button
                  id="filter-button"
                  class="btn btn-primary"
                  type="button"
                  disabled
                >
                  <i class="bi bi-funnel"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card rounded shadow-sm">
      <div class="card-body">
        <table
          class="table table-sm table-bordered table-striped "
          style="font-size: 12px;"
        >
          <thead class="text-center">
            <th>APP NO</th>
            <th>SECPA NO</th>
            <th>CAV NO</th>
            <th>NAME</th>
            <th>PROGRAM</th>
            <th>TYPE</th>
            <th>HEI</th>
            <th>PAYMENT</th>
            <th>CTC</th>
            <th>PURPOSE</th>
            <th>DATE APPLIED</th>
            <th>PROCESSED BY</th>
            <th>RECEIVED BY</th>
            <th>ACTIONS</th>
          </thead>
          <tbody class="text-uppercase" style="font-size:12px">
            <tr v-show="tableLoader">
              <td colspan="18" class="text-center">
                <div class="spinner-border spinner-border-sm mr-2"></div>
                Loading...
              </td>
            </tr>
            <tr v-if="!cavs.length && !tableLoader" class="text-center">
              <td colspan="18">No results found.</td>
            </tr>
            <tr v-for="cav in cavs" :key="cav.id" class="text-center">
              <th>{{ cav.app_id }}</th>

              <td>
                <a href="#" v-if="cav.path" @click.prevent="openUrl(cav.path)"
                  >{{ cav.secpa_no }}
                </a>
                <span v-else>{{ cav.secpa_no }}</span>
              </td>
              <td>
                <a href="#" @click.prevent="print(cav)"
                  >CAV-{{ cav.control_no }}</a
                >
              </td>
              <td class="text-left">
                {{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}

                <span v-if="cav.soNumber !== null" class="form-text">
                  <a
                    href="#"
                    v-if="cav.so_path"
                    @click.prevent="openUrl(cav.so_path)"
                  >
                    {{ cav.soLevel }} {{ cav.soRegion }} {{ cav.soNumber }}
                    {{ cav.soSequence }} {{ cav.soSeries }}</a
                  >
                  <span v-else>
                    {{ cav.soLevel }} {{ cav.soRegion }} {{ cav.soNumber }}
                    {{ cav.soSequence }} {{ cav.soSeries }}</span
                  ></span
                >
              </td>
              <td class="text-left" width="15%">{{ cav.progName }}</td>
              <td>{{ cav.student_type }}</td>

              <td>{{ cav.abbrev }}</td>

              <td>
                &#8369;{{ cav.amount }} ({{ cav.receipt_no }})
                <small class="form-text"
                  >Date: {{ cav.receipt_date | shortDate }}</small
                >
              </td>
              <td>{{ cav.ctc_qty }}</td>
              <td>{{ cav.purpose }}</td>
              <td>{{ cav.date_applied | shortDate }}</td>

              <td class="">
                {{ split(cav.processed_by) }} ({{ cav.created_at | shortDate }})
              </td>

              <td class="text-left">
                <span v-show="cav.received_by"
                  >{{ cav.received_by }}

                  <small class="form-text"
                    >Date Received: {{ cav.received_at | shortDate }}</small
                  >
                </span>
              </td>
              <td>
                <div class="btn-group">
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    @click.prevent="openModal(cav)"
                  >
                    <i class="bi bi-pencil text-primary"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-outline-secondary"
                    @click="openUploadCavModal(cav)"
                  >
                    <i class="bi bi-cloud-arrow-up text-dark"></i>
                  </button>

                  <button
                    class="btn btn-sm btn-outline-secondary"
                    @click.prevent="deleteCav(cav.id, cav.lastname)"
                  >
                    <i class="bi bi-trash text-danger"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <CavModal ref="CavModal" @getCavs="getCavs"></CavModal>
    <PrintCAV v-show="false" ref="PrintCAV"></PrintCAV>

    <CavUploadModal
      ref="CavUploadModal"
      v-on:getCavs="getCavs"
    ></CavUploadModal>
  </div>
</template>

<script>
import CavModal from './components/CavModal.vue';
import PrintCAV from '../CavApplications/components/PrintCAV.vue';
import CavUploadModal from './components/CavUploadModal.vue';

export default {
  components: {
    CavModal,
    PrintCAV,
    CavUploadModal,
  },
  data() {
    return {
      params: {
        secpa_no: '',
        firstname: '',
        lastname: '',
        middlename: '',
      },
      searchValue: '',
      cavs: [],
      tableLoader: false,
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    getCavs() {
      this.cavs = [];
      this.tableLoader = true;

      axios
        .get('api/cavs')
        .then((res) => {
          this.cavs = res.data;
          this.tableLoader = false;
        })
        .catch(() => {
          toastr.error('Something went wrong.', 'Error');
          this.tableLoader = false;
        });
    },
    search() {
      let b = document.getElementById('cav-search-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span>`;
      axios
        .post('api/search-cav', this.params)
        .then((res) => {
          this.cavs = res.data;
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
        })
        .catch((err) => {
          b.disabled = false;
          b.innerHTML = `<i class="bi bi-search text-dark"></i>`;
          toastr.error('Something went wrong. Please try again.', 'Error');
        });
    },

    clear() {
      this.params.secpa_no = '';
      this.params.firstname = '';
      this.params.lastname = '';
      this.params.middlename = '';
    },

    deleteCav(id, lastname) {
      if (
        confirm(
          'Are you sure you want to delete the selected item? \nID: ' +
            id +
            '\nName: ' +
            lastname
        )
      ) {
        axios
          .delete('api/cavs/' + id)
          .then((res) => {
            toastr.success(res.data.msg, 'Information');
            this.getCavs();
          })
          .catch((err) => {
            toastr.error('Something went wrong.', 'Error');
          });
      }
    },

    split(string) {
      let processedBy = string.split(' ');
      return processedBy[0];
    },

    openModal(cav) {
      this.$refs.CavModal.openModal(cav);
    },

    print(cav) {
      this.$refs.PrintCAV.print(cav);
    },

    openUploadCavModal(id) {
      this.$refs.CavUploadModal.openModal(id);
    },

    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
  },
  computed: {},
  mounted() {
    this.getCavs();
  },
};
</script>
