<template>
  <div>
    <div class="modal fade" id="upload-cav-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <form @submit.prevent="uploadCav">
            <div class="modal-body">
              <div class="form-group">
                <label for="">Name</label>
                <h5 class="text-uppercase">
                  {{ cav.firstname }} {{ cav.middlename }} {{ cav.lastname }}
                </h5>
                <span class="form-text"
                  >SECPA No: <b>{{ cav.secpa_no }}</b></span
                >
              </div>
              <div class="form-group">
                <label for="">File</label>
                <input
                  id="upload-cav-file-input"
                  type="file"
                  class="form-control-file"
                  accept="application/pdf"
                  @change="getFile"
                  required
                />
                <small class="form-text text-muted"
                  >File must be on PDF format only</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                id="upload-cav-btn"
                type="submit"
                class="btn btn-primary"
                :disabled="!file"
              >
                Upload
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cav: '',
      file: '',
    };
  },
  methods: {
    openModal(cav) {
      $('#upload-cav-modal').modal('show');
      this.cav = cav;
      document.getElementById('upload-cav-file-input').value = null;
      this.file = '';
    },
    uploadCav() {
      let btn = document.getElementById('upload-cav-btn');
      btn.innerHTML = `<div class="spinner-border spinner-border-sm mr-2"></div> Uploading...`;
      btn.disabled = true;

      let fd = new FormData();

      fd.append('id', this.cav.id);
      fd.append('lastname', this.cav.lastname);
      fd.append('secpa_no', this.cav.secpa_no);
      fd.append('file', this.file);

      axios
        .post('api/cavs/file-upload', fd)
        .then((res) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          $('#upload-cav-modal').modal('hide');
          toastr.success(res.data.msg, 'Information');
          this.$emit('getCavs');
        })
        .catch((err) => {
          btn.disabled = false;
          btn.innerHTML = 'Upload';
          toastr.error('Something went wrong', 'Error');
        });
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
  },
};
</script>
